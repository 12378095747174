<template>
  <section>
    <div class="">
      <!-- modal pour ajouter un nouvel user -->
      <b-modal
        id="modal-add"
        ref="add"
        cancel-variant="outline-secondary"
        ok-title="Login"
        cancel-title="Close"
        centered
        title="Formulaire Utilisateurs"
        hide-footer
      >
        <b-form @submit.prevent>
          <b-row>
            <!-- role -->
            <b-col cols="12">
              <b-form-group label="Role" label-for="vi-first-name">
                <v-select
                  v-model="role"
                  @input="validateRole"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="role_option"
                />

                <small v-if="valideRole" class="text-danger"
                  >Le rôle est est obligatoire</small
                >
              </b-form-group>
            </b-col>

            <!-- name -->
            <b-col cols="12">
              <b-form-group label="Name" label-for="vi-first-name">
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="vi-first-name"
                    v-model="name"
                    @input="validateName"
                    placeholder="Name"
                  />
                </b-input-group>
                <small v-if="valideName" class="text-danger"
                  >Le nom est obligatoire</small
                >
              </b-form-group>
            </b-col>

            <!-- first name -->
            <b-col cols="12">
              <b-form-group label="First Name" label-for="vi-first-name">
                <b-input-group class="input-group-merge">
                  <b-form-input
                    v-model="firstName"
                    @input="validateFirstName"
                    id="vi-first-name"
                    placeholder="First Name"
                  />
                </b-input-group>
                <small v-if="valideFirstName" class="text-danger"
                  >Le prenom est obligatoire</small
                >
              </b-form-group>
            </b-col>

            <!-- email -->
            <b-col cols="12">
              <b-form-group label="Email" label-for="vi-email">
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="vi-email"
                    type="email"
                    v-model="email"
                    @input="validateEmail"
                    placeholder="Email"
                  />
                </b-input-group>
                <small v-if="valideEmail" class="text-danger"
                  >L'email est obligatoire ou incorrect</small
                >
              </b-form-group>
            </b-col>

            <!-- mobile -->
            <b-col cols="12">
              <b-form-group label="Mobile" label-for="vi-mobile">
                <b-input-group class="input-group-merge">
                  <vue-country-code
                    @onSelect="onSelect"
                    optional
                    :preferredCountries="['vn', 'us', 'gb']"
                  >
                  </vue-country-code>
                  <b-form-input
                    id="vi-mobile"
                    v-model="phone"
                    @input="validatePhone"
                    type="number"
                    placeholder="Mobile"
                  />
                </b-input-group>
                <small v-if="validePhone" class="text-danger"
                  >Le numero telephone est obligatoire</small
                >
              </b-form-group>
            </b-col>

            <!-- password -->
            <b-col cols="12">
              <b-form-group label="Password" label-for="vi-password">
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="vi-password"
                    v-model="password"
                    @input="validatePassword"
                    type="password"
                    placeholder="Password"
                  />
                </b-input-group>
                <small v-if="validePassword" class="text-danger"
                  >Le mot de passe est obligatoire</small
                >
              </b-form-group>
            </b-col>

            <!-- reset and submit -->
            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click="add"
              >
                Valider
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
              >
                Effacer
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
      <!-- //modal pour ajouter un nouvel user -->

      <!-- modal pour modifier un user -->
      <b-modal
        id="modal-edit"
        ref="edit"
        cancel-variant="outline-secondary"
        ok-title="Login"
        cancel-title="Close"
        centered
        title="Formulaire Utilisateurs"
        hide-footer
      >
        <b-form @submit.prevent>
          <b-row>
            <!-- role -->
            <b-col cols="12">
              <b-form-group label="Role" label-for="vi-first-name">
                <v-select
                  v-model="Erole"
                  @input="EvalidateRole"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="role"
                />

                <small v-if="EvalideRole" class="text-danger"
                  >Le rôle est est obligatoire</small
                >
              </b-form-group>
            </b-col>

            <!-- name -->
            <b-col cols="12">
              <b-form-group label="Name" label-for="vi-first-name">
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="vi-first-name"
                    v-model="Ename"
                    @input="EvalidateName"
                    placeholder="Name"
                  />
                </b-input-group>
                <small v-if="EvalideName" class="text-danger"
                  >Le nom est obligatoire</small
                >
              </b-form-group>
            </b-col>

            <!-- first name -->
            <b-col cols="12">
              <b-form-group label="First Name" label-for="vi-first-name">
                <b-input-group class="input-group-merge">
                  <b-form-input
                    v-model="EfirstName"
                    @input="EvalidateFirstName"
                    id="vi-first-name"
                    placeholder="First Name"
                  />
                </b-input-group>
                <small v-if="EvalideFirstName" class="text-danger"
                  >Le prenom est obligatoire</small
                >
              </b-form-group>
            </b-col>

            <!-- email -->
            <b-col cols="12">
              <b-form-group label="Email" label-for="vi-email">
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="vi-email"
                    type="email"
                    v-model="Eemail"
                    @input="EvalidateEmail"
                    placeholder="Email"
                  />
                </b-input-group>
                <small v-if="EvalideEmail" class="text-danger"
                  >L'email est obligatoire ou incorrect</small
                >
              </b-form-group>
            </b-col>

            <!-- mobile -->
            <b-col cols="12">
              <b-form-group label="Mobile" label-for="vi-mobile">
                <b-input-group class="input-group-merge">
                  <vue-country-code
                    @onSelect="onSelectupdate"
                    optional
                    :preferredCountries="['vn', 'us', 'gb']"
                  >
                  </vue-country-code>
                  <b-form-input
                    id="vi-mobile"
                    v-model="Ephone"
                    @input="EvalidatePhone"
                    type="number"
                    placeholder="Mobile"
                  />
                </b-input-group>
                <small v-if="EvalidePhone" class="text-danger"
                  >Le numero telephone est obligatoire</small
                >
              </b-form-group>
            </b-col>

            <!-- password -->
            <b-col cols="12">
              <b-form-group label="Password" label-for="vi-password">
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="vi-password"
                    v-model="password"
                    type="password"
                    placeholder="Password"
                    disabled
                  />
                </b-input-group>
                <small v-if="EvalidePassword" class="text-danger"
                  >Le mot de passe est obligatoire</small
                >
              </b-form-group>
            </b-col>

            <!-- reset and submit -->
            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click="modify"
              >
                Valider
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
              >
                Effacer
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
      <!-- //modal pour modifier un user -->

      <!-- Tableau pour afficher les utilisateurs-->
      <div class="tableau">
        <b-card no-body class="">
          <b-row class="px-2 py-2">
            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mt-1"
            >
              <label>Entrées</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block ml-50 mr-1"
              />

              <b-button variant="primary" v-b-modal.modal-add>
                <div class="flex gap-2">
                  <feather-icon icon="PlusIcon" class="mx-auto" />
                  <span>Créer un utilisateur</span>
                </div>
              </b-button>
            </b-col>

            <!-- Search -->
            <b-col cols="12" md="2" class="mt-1">
              <b-form-group>
                <v-select
                  v-model="role"
                  @input="validateRole"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="role_option"
                  placeholder="Filtre par role"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="4" class="mt-1">
              <div class="d-flex align-items-center justify-content-end">
                <b-input-group class="input-group-merge">
                  <b-form-input
                    v-model="filtreUser"
                    class="d-inline-block mr-1"
                    placeholder="Rechercher par : Nom, role, contact"
                  />
                </b-input-group>
              </div>
            </b-col>
          </b-row>
          <b-table
            hover
            responsive
            primary-key="id"
            :per-page="perPage"
            :current-page="currentPage"
            :items="users"
            :fields="tableColumns"
            :filter="filtreUser"
            show-empty
            empty-text=""
            class="bg-white"
          >
            <!-- new butt -->

            <template #cell(roles)="data">
              <b-badge
                pill
                variant="primary"
                v-if="data.item.roles.length > 0"
                >{{ data.item.roles[0].name }}</b-badge
              >
            </template>

            <template #cell(contact)="data">
              <span> +{{ data.item.indicateur }} {{ data.item.contact }} </span>
            </template>

            <template #cell(actions)="data">
              <b-button
                v-b-modal.modal-edit
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                class="btn-icon rounded-circle mr-1"
                @click="edit(data.item)"
              >
                <feather-icon icon="Edit3Icon" />
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                class="btn-icon rounded-circle"
                @click="confirmDestroy(data.item.id)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </template>
          </b-table>

          <!-- Les boutons de pagination -->
          <div class="mx-2 mb-2 pb-1">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"></span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="userTotal"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </div>
    </div>
  </section>
</template>

<script>
import {
  BRow,
  BCard,
  BCol,
  BModal,
  BTable,
  BPagination,
  BFormInput,
  BFormGroup,
  BButton,
  VBModal,
  BForm,
  BLink,
  BFormCheckbox,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BFormTextarea,
  BBadge,
  VBToggle,
} from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import { required, email } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import URL from "@/views/request";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import axios from "axios";
import flatPickr from "vue-flatpickr-component";
import { computed } from "@vue/composition-api";
import VueCountryCode from "vue-country-code-select";

export default {
  components: {
    ToastificationContent,
    VueCountryCode,
    flatPickr,
    vSelect,
    VBToggle,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BModal,
    BTable,
    BPagination,

    BFormGroup,
    VBModal,
    BForm,
    BImg,
    BLink,
    BForm,
    BFormCheckbox,
    BInputGroup,
    BFormTextarea,
    BInputGroupAppend,
    BBadge,

    // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  mixins: [heightTransition],
  data() {
    return {
      name: "",
      firstName: "",
      email: "",
      password: "",
      phone: "",
      indicator: "",
      role: "",

      Ename: "",
      EfirstName: "",
      Eemail: "",
      Epassword: "",
      Ephone: "",
      Eindicator: "",
      Erole: "",
      Eindicator: "",

      // pour pour recuperer la  liste des users
      users: "",

      storageUsers: "",

      //variable pour filtrer en fonction des rôles
      filtreRole: "",

      // validation add
      valideName: false,
      valideFirstName: false,
      validePhone: false,
      valideEmail: false,
      validePassword: false,
      valideRole: false,

      // validation edit
      EvalideName: false,
      EvalideFirstName: false,
      EvalidePhone: false,
      EvalideEmail: false,
      EvalidePassword: false,
      EvalideRole: false,

      role_option:"",
      userTotal: 0,
      filtreUser: "",
      perPage: 30,
      currentPage: 1,
      tableColumns: [
        { key: "nom", label: "nom" },
        { key: "prenoms", label: "prenoms", sortable: true },
        { key: "email", sortable: true },
        { key: "contact", label: "contact", sortable: true },
        { key: "roles", label: "role", sortable: true },
        { key: "actions" },
      ],
      perPageOptions: [30, 50, 100, 150],
    };
  },
  mixins: [heightTransition],

  async mounted() {
          document.title = 'Utilisateur - Ediqia';

    await axios.get(URL.LIST_USER).then((response) => {
      console.log(response.data);
      this.users = response.data.employee;
      this.userTotal = this.users.length;
      this.role_option = response.data.role
      console.log(this.role_option);
      console.log("totaluser", this.users[0].roles.length);
    });
  },

  methods: {
    //edit
    edit(item) {
      localStorage.setItem("user", JSON.stringify(item));

      //recuperation user localstorage
      this.storageUsers = JSON.parse(localStorage.getItem("user"));
      console.log("storageUsers", this.storageUsers);

      //affectation pour modify
      this.Erole = this.storageUsers.roles[0].name;
      this.Ename = this.storageUsers.nom;
      this.EfirstName = this.storageUsers.prenoms;
      this.Eemail = this.storageUsers.email;
      this.Ephone = this.storageUsers.contact;
    },

    //toast
    success(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: "👋 Operation reussi avec success.",
          variant: "success",
        },
      });
    },

    //indicator number phone
    onSelect({ name, iso2, dialCode }) {
      this.indicator = dialCode;
      console.log("indicator", this.indicator);
    },

    onSelectupdate({ dialCode }) {
      const indicator = this.storageUsers.indicateur;
      const indicatorUp = dialCode;
      if (indicator !== indicatorUp) {
        this.Eindicator = indicatorUp;
      } else {
        this.Eindicator = indicator;
      }
      console.log(indicator, indicatorUp);
    },

    //validation des champs add
    validateName() {
      if (!this.name) {
        this.valideName = true;
      } else {
        this.valideName = false;
      }
    },

    validateFirstName() {
      if (!this.firstName) {
        this.valideFirstName = true;
      } else {
        this.valideFirstName = false;
      }
    },

    validateEmail() {
      const pattern =
        /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      if (!this.email.match(pattern)) {
        this.valideEmail = true;
      } else {
        this.valideEmail = false;
      }
    },

    validatePhone() {
      if (!this.phone) {
        this.validePhone = true;
      } else {
        this.validePhone = false;
      }
    },

    validatePassword() {
      if (!this.password) {
        this.validePassword = true;
      } else {
        this.validePassword = false;
      }
    },

    validateRole(e) {
      this.filtreUser = e.name
      if (!this.role) {
        
        this.valideRole = true;
      } else {
        this.valideRole = false;
      }
    },

    //validation des champs edit
    EvalidateName() {
      if (!this.Ename) {
        this.EvalideName = true;
      } else {
        this.EvalideName = false;
      }
    },

    EvalidateFirstName() {
      if (!this.EfirstName) {
        this.EvalideFirstName = true;
      } else {
        this.EvalideFirstName = false;
      }
    },

    EvalidateEmail() {
      const pattern =
        /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      if (!this.Eemail.match(pattern)) {
        this.EvalideEmail = true;
      } else {
        this.EvalideEmail = false;
      }
    },

    EvalidatePhone() {
      if (!this.Ephone) {
        this.EvalidePhone = true;
      } else {
        this.EvalidePhone = false;
      }
    },

    EvalidateRole() {
      if (!this.Erole) {
        this.EvalideRole = true;
      } else {
        this.EvalideRole = false;
      }
    },

    //fonctionn add

    async add() {
      // this.success();
      try {
        this.validateName();
        this.validateFirstName();
        this.validateEmail();
        this.validatePhone();
        this.validatePassword();
        this.validateRole();

        const data = {
          nom: this.name,
          prenoms: this.firstName,
          email: this.email,
          contact: this.phone,
          indicateur: this.indicator,
          password: this.password,
          role: this.role.title,
        };

        console.log(data);

        if (
          this.name ||
          this.firstName ||
          this.email ||
          this.phone ||
          this.password ||
          this.role
        ) {
          await axios.post(URL.REGISTER, data).then((response) => {
            if (response.data) {
              this.success();
              this.$refs.add.hide();
              this.users.push(response.data.user);
              console.log(response.data);
              this.name = "";
              this.firstName = "";
              this.email = "";
              this.role = "";
              this.password = "";
              this.phone = "";
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
    },

    //fonction update

    async modify() {
      try {
        this.EvalidateName();
        this.EvalidateFirstName();
        this.EvalidateEmail();
        this.EvalidatePhone();
        this.EvalidateRole();

        let roleSend="";
        if (this.Erole ===this.storageUsers.roles[0].name) {
          roleSend = this.storageUsers.roles[0].name
        } else {
          roleSend = this.Erole.title
        }


        const data = {
          id: this.storageUsers.id,
          role:roleSend,
          nom: this.Ename,
          prenoms: this.EfirstName,
          email: this.Eemail,
          contact: this.Ephone,
          indicateur: this.Eindicator,
        };
        await axios.post(URL.USER_UPDATE, data).then((response) => {
           if (response.data) {
              this.success();
              this.$refs.edit.hide();
              this.users.unshift(data);
              axios.get(URL.LIST_USER).then((response) => {
                console.log(response.data);
                this.users = response.data.employee;
                this.userTotal = this.users.length;
              });
            }
        });

        console.log("edit", data);
      } catch (error) {
        console.log(error);
      }
    },

    //fonction destroy

    confirmDestroy(id) {
      this.$swal({
      title: "Êtes vous sûr?",
        text: "Ce utilisateur sera supprimé définitivement !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.delete(id);
          this.$swal({
            icon: "success",
            title: "Deleted!",
            text: "Your file has been deleted.",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      });
    },

    delete(identifiant) {
      try {
        const id = {
          id: identifiant,
        };

        axios.post(URL.USER_DESTROY, id).then((response) => {
          if (response.data) {
            axios.get(URL.LIST_USER).then((response) => {
              console.log(response.data);
              this.users = response.data.employee;
              this.userTotal = this.users.length;
              console.log("totaluser", this.users[0].roles.length);
            });
          }
        });

        this.users.splice(index, 1);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.add-new-client-header {
  padding: $options-padding-y $options-padding-x;
  color: $success;
  &:hover {
    background-color: rgba($success, 0.12);
  }
}

.tableau {
  box-shadow: 0px 6px 46px -21px rgba(0, 0, 0, 0.75);
}

.table-card {
  width: 100%;
  margin: auto;
  border-radius: 13px;
}

.table-card thead tr {
  border-radius: 13px;
  background-color: rgb(68, 68, 68) !important;
}

.table-card thead tr th {
  background-color: rgb(68, 68, 68) !important;
  color: white;
}

.add-btn {
  position: absolute;
  right: 0;
  top: -50px;
  background-color: #450077;
}

.none {
  display: none;
}
.block {
  display: inline-block;
}
</style>